<template>
	<v-container class="main-login">
		<div
			class="main-login__bg"
			:style="{
				backgroundImage:
					'url(' + require('../../assets/images/background.jpg') + ')',
			}"
		>
			<img
				class="main-login__bg-logo"
				:src="require('@/assets/images/logo-confipetrol.png')"
				alt=""
			/>
		</div>
		<div class="main-login__form">
			<recover-password />
		</div>
	</v-container>
</template>

<script>
import RecoverPassword from '@/components/auth/RecoverPassword/RecoverPassword.vue';
export default {
	name: 'RecoverPasswordView',
	components: {
		RecoverPassword,
	},
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/login.scss';
</style>
