import { mapActions } from 'vuex';
import { $EventBus } from '@/main';

export default {
	name: 'FormLogin',
	data: () => ({
		show1: false,
		valid: true,
		user: '',
		password: '',
		error: null,
		isLoadingData: false,

		show: false,
		editedItem: {
			password: '',
			confirmPassword: '',
		},
		defaultItem: {
			password: '',
			confirmPassword: '',
		},
		passwordRules: [
			(v) => !!v || 'Campo requerido',
			(v) => (v && v.length >= 6) || 'La contraseña debe tener mínimo 6 caracteres',
			(v) =>
				/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]/.test(v) ||
				'La contraseña debe tener una letra, un número y un carácter especial (@$!%*#?&)',
		],
	}),

	computed: {
		confirmPasswordRules() {
			return [
				(v) => !!v || 'Campo requerido',
				(v) =>
					(v && v.length >= 6) ||
					'La contraseña debe tener mínimo 6 caracteres',
				(v) => v == this.editedItem.password || 'La contraseña no coincide',
			];
		},
	},

	created() {
		this.validToken();
	},

	mounted() {},

	methods: {
		...mapActions('auth', ['passwordRecoveryToken', 'passwordUpdateRecovery']),

		async validToken() {
			let { ok, message } = await this.passwordRecoveryToken({
				token: this.$route.params.token,
				application_id: parseInt(process.env.VUE_APP_ID),
			});
			if (!ok) {
				this.$router.push({ name: 'Login' });
				if (Array.isArray(message)) {
					message = message.join(' ');
				}
				$EventBus.$emit('showSnack', 'error', message);
			}
		},
		async validate() {
			let isvalidated = this.$refs.form.validate();
			if (isvalidated) {
				this.isLoadingData = true;
				this.error = null;
				let { ok, message } = await this.passwordUpdateRecovery({
					token: this.$route.params.token,
					password: this.editedItem.password,
					confirm_password: this.editedItem.confirmPassword,
					application_id: parseInt(process.env.VUE_APP_ID),
				});
				if (ok) {
					this.reset();
					this.$router.push({ name: 'Login' });
					$EventBus.$emit('showSnack', 'success', message);
				} else {
					if (Array.isArray(message)) {
						message = message.join(' ');
					}
					this.error = message;
				}
				this.isLoadingData = false;
			}
		},
		reset() {
			this.$refs.form.reset();
		},
		resetValidation() {
			this.$refs.form.resetValidation();
		},
	},
};
